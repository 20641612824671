import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        <Route path={`${APP_PREFIX_PATH}/doctors`} component={lazy(() => import(`./doctors`))} />
        <Route path={`${APP_PREFIX_PATH}/sellers`} component={lazy(() => import(`./sellers`))} />
        <Route path={`${APP_PREFIX_PATH}/patients`} component={lazy(() => import(`./patients`))} />
        <Route path={`${APP_PREFIX_PATH}/companys`} component={lazy(() => import(`./companys`))} />
        <Route path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./products`))} />
        <Route path={`${APP_PREFIX_PATH}/upload-csv`} component={lazy(() => import(`./upload-csv`))} />
        <Route path={`${APP_PREFIX_PATH}/combos`} component={lazy(() => import(`./combos`))} />
        <Route path={`${APP_PREFIX_PATH}/coupons`} component={lazy(() => import(`./coupons`))} />
        <Route path={`${APP_PREFIX_PATH}/notification`} component={lazy(() => import(`./notification`))} />
        <Route path={`${APP_PREFIX_PATH}/order`} component={lazy(() => import(`./order`))} />
        <Route path={`${APP_PREFIX_PATH}/tags`} component={lazy(() => import(`./tags`))} /> 
        <Route path={`${APP_PREFIX_PATH}/financial`} component={lazy(() => import(`./financial`))} />
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${APP_PREFIX_PATH}/tracking`} component={lazy(() => import(`./tracking`))} />
        <Route path={`${APP_PREFIX_PATH}/payments-new`} component={lazy(() => import(`./payments`))} />
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
