import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import { notification } from 'antd';
import { AUTH_TOKEN } from 'redux/constants/Auth'
import history from '../history'


const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})

const InvoicesServices = {}

const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

const jwtToken = localStorage.getItem(AUTH_TOKEN)

service.interceptors.request.use(config => {


    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

InvoicesServices.create = function (data) {
    return service({
        url: `/invoices-orders`,
        method: 'post',
        data: data
    })
}

InvoicesServices.list = function () {
    return service({
        url:  `/invoices-orders`,
        method: 'get',
    })
}

InvoicesServices.list_order = function (data) {
    return service({
        url:  `/invoices-orders-list`,
        method: 'post',
        data: data
    })
}

InvoicesServices.list_id = function (id) {
    return service({
        url:  `/invoices-orders/${id}}`,
        method: 'get',
    })
}

InvoicesServices.update_validate = function (id,data) {
    return service({
        url: `/invoices/${id}`,
        method: 'put',
        data: data
    })
}

InvoicesServices.delete = function (id) {
    return service({
        url:  `/invoices-delete/${id}`,
        method: 'put',
    })
}


// API respone interceptor
service.interceptors.response.use((response) => {
   
    return response.data
}, (error) => {

    let notificationParam = {
        message: ''
    }

     // Remove token and redirect 
     if (error.response.status === 400 ) {
    
        notificationParam.message = error.response.data.error
        return {status : error.response.status, message: error.response.data.error};
    }

    // Remove token and redirect 
    if ( error.response.status === 403 || error.response.status === 401) {
        notificationParam.message = 'Ocorreu um erro durante o processo'
        notificationParam.description = 'Por favor, tente novamente'
    }

    if (error.response.status === 404) {
       notificationParam.message = 'Não foi possível encontrar o recurso solicitado'
    }

    if (error.response.status === 422) {
        localStorage.removeItem(AUTH_TOKEN);
		localStorage.removeItem('user_info');
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }

    if (error.response.status === 500) {
        notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
        notificationParam.message = 'Time Out'
    }

    notification.error(notificationParam)

    //return Promise.reject(error);
    return notificationParam.message;
});


export default InvoicesServices