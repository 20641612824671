import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect, useSelector } from 'react-redux'
import {
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
  {
    title: "Editar Perfil",
    icon: EditOutlined,
    path: "/"
  },
]

const getType = (type) => {
  if (type === 'admin') {
    return 'Administrador'
  } else if (type === 'Vendedor') {
    return 'Gestor de Contrato'
  } else if (type === 'Médico') {
    return 'Prescritor'
  } else if (type === 'Empresa') {
    return 'Clínica'
  }
}

export const NavProfile = ({ signOut }) => {
  const profileImg = "/img/avatars/user.jpg";

  const name = useSelector(status => status.auth.name)
  const type = useSelector(status => status.auth.type_user)


  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted">{getType(type)}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/*menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })*/}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sair</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile)
