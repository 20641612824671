import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavConfigPerson";
import { connect } from "react-redux";
import utils from 'utils'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { onMobileNavToggle } from "redux/actions/Theme";
import PermissionGate from "../shared-components/PermissionGate"

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = "";
    if (key) {
        const arr = key.split("-");
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const TopNavContent = (props) => {
    const { topNavColor, localization } = props;
    return (
        <Menu mode="horizontal" style={{ backgroundColor: '#FBFBFC' }}>
            {navigationConfig.map((menu) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                            <span className="d-flex align-items-center">
                                {menu.icon ? <Icon type={menu?.icon} /> : null}
                                <span>{setLocale(localization, menu.title)}</span>
                            </span>
                        }
                    >
                        {menu.submenu.map((subMenuFirst) =>
                            subMenuFirst.submenu.length > 0 ? (
                                <SubMenu
                                    key={subMenuFirst.key}
                                    popupClassName="top-nav-menu"
                                    icon={
                                        subMenuFirst.icon ? (
                                            <Icon type={subMenuFirst?.icon} />
                                        ) : null
                                    }
                                    title={setLocale(localization, subMenuFirst.title)}
                                >
                                    {subMenuFirst.submenu.map((subMenuSecond) => (
                                        <Menu.Item key={subMenuSecond.key}>
                                            <span>
                                                {setLocale(localization, subMenuSecond.title)}
                                            </span>
                                            <Link to={subMenuSecond.path} />
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            ) : (
                                <Menu.Item key={subMenuFirst.key}>
                                    {subMenuFirst.icon ? (
                                        <Icon type={subMenuFirst?.icon} />
                                    ) : null}
                                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                                    <Link to={subMenuFirst.path} />
                                </Menu.Item>
                            )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon} /> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path} /> : null}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

const MenuContent = (props) => {
    return <TopNavContent {...props} />
};

const mapStateToProps = ({ theme }) => {
    const { sideNavTheme, topNavColor } = theme;
    return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
