import {
    DashboardOutlined,
    QuestionCircleOutlined,
    RiseOutlined,
    HomeOutlined,
    FileDoneOutlined,
    ClusterOutlined,
    UserAddOutlined
  } from '@ant-design/icons';
  import { APP_PREFIX_PATH } from 'configs/AppConfig'
  
  const dashBoardNavTree = [{
    key: 'home',
    role: 'fdee8845ed8040424ad8441341980265,7025e2feda0b8cba2b0a7a859fd6036a,6387b22cf1e307f86e271130fb56531c,d075b55dca3fb2c35e395fc32c90554f',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.home',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: []
  }]
  
  const orderMenu = [{
    key: 'order',
    role: 'fdee8845ed8040424ad8441341980265',
    path: `${APP_PREFIX_PATH}/order/new`,
    title: 'sidenav.pedidos',
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'order-new',
        path: `${APP_PREFIX_PATH}/order/new`,
        title: 'sidenav.pedidos.new',
        icon: '',
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'order-new-add',
        path: `${APP_PREFIX_PATH}/order/new-add`,
        title: 'sidenav.pedidos.new-add',
        icon: '',
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'order-list',
        path: `${APP_PREFIX_PATH}/order/list`,
        title: 'sidenav.pedidos.list',
        icon: '',
        breadcrumb: true,
        submenu: []
      },
    ]
  }]

  const patientMenu = [{
    key: 'patient',
    role: 'fdee8845ed8040424ad8441341980265',
    path: `${APP_PREFIX_PATH}/patients`,
    title: 'sidenav.patient',
    icon: UserAddOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'patient-new',
        path: `${APP_PREFIX_PATH}/patients/new`,
        title: 'sidenav.patient.doctor.new',
        icon: '',
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'patient-list',
        path: `${APP_PREFIX_PATH}/patients/list`,
        title: 'sidenav.patient.doctor.list',
        icon: '',
        breadcrumb: true,
        submenu: []
      },
    ]
  }]

  const laudosMenu = [{
    key: 'panel',
    role: 'fdee8845ed8040424ad8441341980265',
    path: `${APP_PREFIX_PATH}/products/list`,
    title: 'sidenav.product.list',
    icon: ClusterOutlined,
    breadcrumb: false,
    submenu: []
  }]

  const salesMenu = [{
    key: 'sales',
    role: 'fdee8845ed8040424ad8441341980265',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.sale.doctor',
    icon: RiseOutlined,
    breadcrumb: false,
    submenu: []
  }]

  const coletaMenu = [{
    key: 'coleta',
    role: 'fdee8845ed8040424ad8441341980265',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.doctor.coleta',
    icon: QuestionCircleOutlined,
    breadcrumb: false,
    submenu: []
  }]




  
  const navigationConfig = [
    ...dashBoardNavTree,
    ...orderMenu,
    ...patientMenu,
    ...laudosMenu,
    //...salesMenu,
    //...coletaMenu
    
  ]
  
  export default navigationConfig;
  