const initState = {
	user: localStorage.getItem('user'),
	name: localStorage.getItem('name'),
	type: localStorage.getItem('type'),
}

const user = (state = initState, action) => {
	switch (action.type) {
		case 'LOGINUSER':
			return {
				...state,
				user: action.user,
				name: action.name,
				type: action.type,
			}

		case 'LOGOUTUSER':
			return {
				...state,
				user: null,
				name: null,
				type: null,
			}
		default:
			return state;
	}
}

export default user