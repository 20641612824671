import {
  DashboardOutlined,
  IdcardOutlined,
  MedicineBoxOutlined,
  HomeOutlined,
  ShoppingOutlined,
  ReconciliationOutlined,
  TagOutlined,
  SolutionOutlined,
  TagsOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  BarChartOutlined,
  QuestionCircleOutlined

} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'sidenav.home',
  icon: '',
  breadcrumb: true,
  submenu: [
    {
      key: 'home-title',
      role: 'ba3988db0a3167093b1f74e8ae4a8e83',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'sidenav.home',
      icon: HomeOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'admin-sub',
      role: 'e940f7d05beef544f58c9739dd19c79a',
      path: `${APP_PREFIX_PATH}`,
      title: 'sidenav.register.user',
      icon: SolutionOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-new',
          role: '8941a3112f8d9f7ba10b995d9243a17a',
          path: `${APP_PREFIX_PATH}/admin/new`,
          title: 'sidenav.admin.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'seller-new',
          role: '148b768454407db76ed1a4f8470eb974',
          path: `${APP_PREFIX_PATH}/sellers/new`,
          title: 'sidenav.seller.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'company-new',
          role: '2fa912ffc44a3e7ab7adf0038d798f4e',
          path: `${APP_PREFIX_PATH}/companys/new`,
          title: 'sidenav.company.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'doctor-down-new',
          role: 'a25d0a46b4a3fc6bcc60d8a1251d55a4',
          path: `${APP_PREFIX_PATH}/doctors/new`,
          title: 'sidenav.doctor.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'patient-new',
          role: '76ea0bebb3c22822b4f0dd9c9fd021c5',
          path: `${APP_PREFIX_PATH}/patients/new`,
          title: 'sidenav.patient.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'product-new',
          role: 'bb594b10b4e7f862cc781c5fe87a8322',
          path: `${APP_PREFIX_PATH}/products/direction`,
          title: 'sidenav.product.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'product-new-clinic',
          role: 'bb594b10b4e7f862cc781c5fe87a8322',
          path: `${APP_PREFIX_PATH}/products/direction-clinic`,
          title: 'sidenav.product.direction.clinic',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'coupons-new',
          role: '67cf22074a529ce9b62ef9cfd3dac5d8',
          path: `${APP_PREFIX_PATH}/coupons/new`,
          title: 'sidenav.coupons.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'notification-new',
          role: '8941a3112f8d9f7ba10b995d9243a17a',
          path: `${APP_PREFIX_PATH}/notification/new`,
          title: 'sidenav.notification.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'upload-csv',
          role: '8941a3112f8d9f7ba10b995d9243a17a',
          path: `${APP_PREFIX_PATH}/upload-csv`,
          title: 'sidenav.upload.csv',
          icon: '',
          breadcrumb: true,
          submenu: []
        },

      ]
    },
    {
      key: 'consult-sub',
      role: '5e73b42456347af1be4be2d0c8eda64a',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'sidenav.consult',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-list',
          role: 'e0068fc79ad227c7549334db69c372ac',
          path: `${APP_PREFIX_PATH}/admin/list`,
          title: 'sidenav.admin.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'seller-list',
          role: 'eda01d40a2858fa244c96b04b22fef10',
          path: `${APP_PREFIX_PATH}/sellers/list`,
          title: 'sidenav.seller.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'company-list',
          role: '32e0888cb603523494c4849e72f6226f',
          path: `${APP_PREFIX_PATH}/companys/list`,
          title: 'sidenav.company.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'doctor-down-list',
          role: 'bb1456a09b5e30cffe8168223e338530',
          path: `${APP_PREFIX_PATH}/doctors/list`,
          title: 'sidenav.doctor.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'patient-list',
          role: 'de78bbff8bf6876a1834b38339a62724',
          path: `${APP_PREFIX_PATH}/patients/list`,
          title: 'sidenav.patient.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'coupons-list',
          role: '2abdfa9834957086d53423b1659bf905',
          path: `${APP_PREFIX_PATH}/coupons/list`,
          title: 'sidenav.coupons.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'tag-sub',
      role: 'c592e5ae228fdc96a514a6fb341ca335,5cde7c39ad8488f541502a6e9395ef29,3697f2a0d424e04d0636aa8a0b078820',
      path: `${APP_PREFIX_PATH}/tags`,
      title: 'sidenav.tag',
      icon: TagsOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'tag-new',
          role: 'c592e5ae228fdc96a514a6fb341ca335',
          path: `${APP_PREFIX_PATH}/tags/new`,
          title: 'sidenav.tag.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'tag-list',
          role: '5cde7c39ad8488f541502a6e9395ef29',
          path: `${APP_PREFIX_PATH}/tags/list`,
          title: 'sidenav.tag.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'tag-direction',
          role: '3697f2a0d424e04d0636aa8a0b078820',
          path: `${APP_PREFIX_PATH}/tags/direction`,
          title: 'sidenav.tag.direction',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'order-sub',
      role: '75cca03f77b0f13ea89ebc6c51a28996,23766c4cc5b6cd5f155fff4aac3ff94e',
      path: `${APP_PREFIX_PATH}/order`,
      title: 'sidenav.pedidos',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'order-new',
          role: '23766c4cc5b6cd5f155fff4aac3ff94e',
          path: `${APP_PREFIX_PATH}/order/new`,
          title: 'sidenav.pedidos.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'order-new-add',
          role: '23766c4cc5b6cd5f155fff4aac3ff94e',
          path: `${APP_PREFIX_PATH}/order/new-add`,
          title: 'sidenav.pedidos.new-add',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'order-list',
          role: '75cca03f77b0f13ea89ebc6c51a28996',
          path: `${APP_PREFIX_PATH}/order/list`,
          title: 'sidenav.pedidos.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'tracking-sub',
      role: '3e63b3cc6215715f38573f548ad9005b',
      path: `${APP_PREFIX_PATH}/tracking`,
      title: 'sidenav.tracking',
      icon: EnvironmentOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'tracking-new',
          role: '3e63b3cc6215715f38573f548ad9005b',
          path: `${APP_PREFIX_PATH}/tracking/consult`,
          title: 'sidenav.tracking.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'financial-sub',
      role: '4aa006d94e21c5c68063dd57aaddc8bb',
      path: `${APP_PREFIX_PATH}/financial`,
      title: 'sidenav.financial',
      icon: CreditCardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'financial-new',
          role: '4aa006d94e21c5c68063dd57aaddc8bb',
          path: `${APP_PREFIX_PATH}/financial/sales-order`,
          title: 'sidenav.financial-list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'financial-invoiced',
          role: '58290b3c78f67dd07a86678c23ba23cd',
          path: `${APP_PREFIX_PATH}/financial/invoices-order/list`,
          title: 'sidenav.financial-invoiced',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'financial-invoiced-new',
          role: '58290b3c78f67dd07a86678c23ba23cd',
          path: `${APP_PREFIX_PATH}/financial/invoices-order/new`,
          title: 'sidenav.financial-invoiced-new',
          icon: '',
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'reports-sub',
      role: 'c9a357e2fc32460d58ac26453538bd7c',
      path: `${APP_PREFIX_PATH}/reports`,
      title: 'sidenav.reports',
      icon: BarChartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'reports-new',
          role: 'c9a357e2fc32460d58ac26453538bd7c',
          path: `${APP_PREFIX_PATH}/reports/reports-patients`,
          title: 'sidenav.reports.patients',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'reports-orders',
          role: 'c9a357e2fc32460d58ac26453538bd7c',
          path: `${APP_PREFIX_PATH}/reports/reports-orders`,
          title: 'sidenav.reports.orders',
          icon: '',
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'support-sub',
      role: '616e96a5090dfe2b18def7e9b9dcd915',
      path: `${APP_PREFIX_PATH}/support`,
      title: 'sidenav.support',
      icon: QuestionCircleOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'support-new',
          role: '616e96a5090dfe2b18def7e9b9dcd915',
          path: `${APP_PREFIX_PATH}/support/new`,
          title: 'sidenav.support',
          icon: '',
          breadcrumb: true,
          submenu: []
        }
      ]
    },
  ]
}]

// MENU COMPANY
const companyMenu = [{
  key: 'companyAdmin',
  role: 'd075b55dca3fb2c35e395fc32c90554f',
  path: `${APP_PREFIX_PATH}/admin`,
  title: 'sidenav.admin',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'companyDoctor-sub',
      role: 'd075b55dca3fb2c35e395fc32c90554f',
      path: `${APP_PREFIX_PATH}/doctors`,
      title: 'sidenav.doctor',
      icon: MedicineBoxOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'companyDoctor-down-new',
          path: `${APP_PREFIX_PATH}/doctors/new`,
          title: 'sidenav.doctor.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'companyDoctor-down-list',
          path: `${APP_PREFIX_PATH}/doctors/list`,
          title: 'sidenav.doctor.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'companyPatient-sub',
      role: 'd075b55dca3fb2c35e395fc32c90554f',
      path: `${APP_PREFIX_PATH}/patients`,
      title: 'sidenav.patient',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'companyPatient-new',
          path: `${APP_PREFIX_PATH}/patients/new`,
          title: 'sidenav.patient.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'companyPatient-list',
          path: `${APP_PREFIX_PATH}/patients/list`,
          title: 'sidenav.patient.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },

  ]
}]

const companyproductMenu = [{
  key: 'companyProduct',
  role: 'd075b55dca3fb2c35e395fc32c90554f',
  path: `${APP_PREFIX_PATH}/products`,
  title: 'sidenav.product',
  icon: ShoppingOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'companyProduct-sub',
      path: `${APP_PREFIX_PATH}/products`,
      title: 'sidenav.product',
      icon: ShoppingOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'companyProduct-new',
          path: `${APP_PREFIX_PATH}/products/list`,
          title: 'sidenav.product.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'companyOrder',
      role: 'd075b55dca3fb2c35e395fc32c90554f',
      path: `${APP_PREFIX_PATH}/order`,
      title: 'sidenav.pedidos',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'companyOrder-new',
          path: `${APP_PREFIX_PATH}/order/new`,
          title: 'sidenav.pedidos.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'companyOrder-new-combo',
          path: `${APP_PREFIX_PATH}/order/new-combo`,
          title: 'sidenav.pedidos.new-combo',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'companyOrder-list',
          path: `${APP_PREFIX_PATH}/order/list`,
          title: 'sidenav.pedidos.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'companyCoupons',
      role: 'd075b55dca3fb2c35e395fc32c90554f',
      path: `${APP_PREFIX_PATH}/coupons`,
      title: 'sidenav.coupons',
      icon: TagOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'companyCoupons-new',
          path: `${APP_PREFIX_PATH}/coupons/list`,
          title: 'sidenav.coupons.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'companyCoupons-list',
          path: `${APP_PREFIX_PATH}/coupons/new`,
          title: 'sidenav.coupons.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

const companyPaymentMenu = [{
  key: 'companyPayment',
  role: 'd075b55dca3fb2c35e395fc32c90554f',
  path: `${APP_PREFIX_PATH}/payments-new`,
  title: 'sidenav.payment',
  icon: CreditCardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'companyPayment-sub',
      path: `${APP_PREFIX_PATH}/payments-new`,
      title: 'sidenav.payment',
      icon: CreditCardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'companyPayment-new',
          path: `${APP_PREFIX_PATH}/payments-new/new`,
          title: 'sidenav.payment.new',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'companyPayment-token',
          path: `${APP_PREFIX_PATH}/payments-new/token`,
          title: 'sidenav.payment.token',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]


const adminMenu = [{
  key: 'admin',
  role: 'fdee8845ed8040424ad8441341980265',
  path: `${APP_PREFIX_PATH}/admin`,
  title: 'sidenav.register.user',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'admin-sub',
      role: 'fdee8845ed8040424ad8441341980265',
      title: 'sidenav.register.user',
      icon: DashboardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-list',
          path: `${APP_PREFIX_PATH}/admin/new`,
          title: 'sidenav.admin.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'seller-new',
          path: `${APP_PREFIX_PATH}/sellers/new`,
          title: 'sidenav.seller.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'company-new',
          path: `${APP_PREFIX_PATH}/companys/new`,
          title: 'sidenav.company.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'doctor-down-new',
          path: `${APP_PREFIX_PATH}/doctors/new`,
          title: 'sidenav.doctor.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'patient-new',
          path: `${APP_PREFIX_PATH}/patients/new`,
          title: 'sidenav.patient.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'product-new',
          path: `${APP_PREFIX_PATH}/products/list`,
          title: 'sidenav.product.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'combos-new',
          path: `${APP_PREFIX_PATH}/combos/new`,
          title: 'sidenav.combo.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        }, {
          key: 'coupons-new',
          path: `${APP_PREFIX_PATH}/coupons/list`,
          title: 'sidenav.coupons.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },

      ]
    },
    /* {
       key: 'direction-sub',
       role: 'fdee8845ed8040424ad8441341980265',
       path: `${APP_PREFIX_PATH}/products/direction`,
       title: 'sidenav.product.direction',
       icon: SwapOutlined,
       breadcrumb: true,
       submenu: []
     }, */
  ]
}]

const consultMenu = [{
  key: 'consult',
  role: 'fdee8845ed8040424ad8441341980265',
  path: `${APP_PREFIX_PATH}/consult`,
  title: 'sidenav.consult',
  icon: IdcardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'consult-sub',
      role: 'fdee8845ed8040424ad8441341980265',
      title: 'sidenav.consult',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-list',
          path: `${APP_PREFIX_PATH}/admin/list`,
          title: 'sidenav.admin.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'seller-list',
          path: `${APP_PREFIX_PATH}/sellers/list`,
          title: 'sidenav.seller.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'company-list',
          path: `${APP_PREFIX_PATH}/companys/list`,
          title: 'sidenav.company.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'doctor-down-list',
          path: `${APP_PREFIX_PATH}/doctors/list`,
          title: 'sidenav.doctor.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'patient-list',
          path: `${APP_PREFIX_PATH}/patients/list`,
          title: 'sidenav.patient.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'combos-list',
          path: `${APP_PREFIX_PATH}/combos/list`,
          title: 'sidenav.combo.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'coupons-list',
          path: `${APP_PREFIX_PATH}/coupons/new`,
          title: 'sidenav.coupons.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

const tagMenu = [{
  key: 'tag',
  role: 'fdee8845ed8040424ad8441341980265',
  path: `${APP_PREFIX_PATH}/tag`,
  title: 'sidenav.tag',
  icon: ReconciliationOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'tag-sub',
      role: 'fdee8845ed8040424ad8441341980265',
      path: `${APP_PREFIX_PATH}/tag`,
      title: 'sidenav.tag',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'tag-new',
          path: `${APP_PREFIX_PATH}/tag/new`,
          title: 'sidenav.tag.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'tag-list',
          path: `${APP_PREFIX_PATH}/tag/list`,
          title: 'sidenav.tag.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

const orderMenu = [{
  key: 'order',
  role: 'fdee8845ed8040424ad8441341980265',
  path: `${APP_PREFIX_PATH}/order`,
  title: 'sidenav.pedidos',
  icon: ReconciliationOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'order-sub',
      role: 'fdee8845ed8040424ad8441341980265',
      path: `${APP_PREFIX_PATH}/order`,
      title: 'sidenav.pedidos',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'order-new',
          path: `${APP_PREFIX_PATH}/order/new`,
          title: 'sidenav.pedidos.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'order-new-combo',
          path: `${APP_PREFIX_PATH}/order/new-combo`,
          title: 'sidenav.pedidos.new-combo',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'order-list',
          path: `${APP_PREFIX_PATH}/order/list`,
          title: 'sidenav.pedidos.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

const trackingMenu = [{
  key: 'tracking',
  role: 'fdee8845ed8040424ad8441341980265',
  path: `${APP_PREFIX_PATH}/tracking`,
  title: 'sidenav.tracking',
  icon: ReconciliationOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'tracking-sub',
      role: 'fdee8845ed8040424ad8441341980265',
      path: `${APP_PREFIX_PATH}/tracking`,
      title: 'sidenav.tracking',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'tracking-new',
          path: `${APP_PREFIX_PATH}/tracking/new`,
          title: 'sidenav.tracking.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'tracking-list',
          path: `${APP_PREFIX_PATH}/tracking/list`,
          title: 'sidenav.tracking.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

const paymentMenu = [{
  key: 'payment',
  role: 'fdee8845ed8040424ad8441341980265',
  path: `${APP_PREFIX_PATH}/payments-new`,
  title: 'sidenav.payment',
  icon: CreditCardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'payment-sub',
      path: `${APP_PREFIX_PATH}/payments-new`,
      title: 'sidenav.payment',
      icon: CreditCardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'payment-new',
          path: `${APP_PREFIX_PATH}/payments-new/new`,
          title: 'sidenav.payment.new',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'payment-token',
          path: `${APP_PREFIX_PATH}/payments-new/token`,
          title: 'sidenav.payment.token',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  //...adminMenu,
  //...consultMenu,
  // ...tagMenu,
  // ...orderMenu,
  // ...trackingMenu,
  // ...paymentMenu,

  //...companyMenu,
  //...companyproductMenu,

]

export default navigationConfig;
