export const loginUser = (user,name,type) => {
  return {
    type: 'LOGINUSER',
    user,
    name,
    type,
  }
};


export const logoutUser = (user,name,type) => {
  return {
    type: 'LOGINUSER',
    user,
    name,
    type,
  }
};

